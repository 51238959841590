@import "../../Theme/variables.scss";


label.customCbx {
  display        : flex;
  flex-direction : row;
  align-items    : center;
}

input.customCbx ~ span.customCbx {
  border        : 2px solid #000;
  border-radius : 0;
  height        : 20px;
  width         : 20px;
  display       : block;
  cursor        : pointer;
}

input.customCbx:checked ~ span.customCbx {
  background-image : none;
  border           : 2px solid #000;
  margin           : 0;
}

input.customCbx:checked ~ span.customCbx::before {
  content       : "";
  width         : 7px;
  height        : 12px;
  border-bottom : 2px solid #000;
  border-right  : 2px solid #000;
  display       : block;
  margin-left   : 4px;
  transform     : rotate(45deg);
}

.customCbxText {
  flex         : 1;
  width        : auto;
  padding-left : 1em;
  font-weight  : 400;
  cursor       : pointer;
  font-size    : .9em;
}

.smallInfoText {
  font-size : .9em;
}
