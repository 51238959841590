@import "../../Theme/variables.scss";

$tablePaddingY : 1em;

.bgLight {
  background-color : $gray;
  border           : none !important;
}

.bgNone {
  background-color : transparent;
  border           : none !important;
}

.bgRed {
  background-color : $red;
  color            : $white;
}

.vAlign {
  vertical-align : middle;
}

.results {
  th {
	font-weight : 700;
	font-size   : 1.1em;
  }

  th, td {
	padding      : $tablePaddingY .7em;
	border-left  : 1px solid $white;
	border-right : 1px solid $white;
  }
}

.titleCell {
  font-weight : 700;
}

.dropdownTitleCell {
  position      : relative;
  padding-right : 2.5em !important;
  cursor        : pointer;
}

.plus {
  position         : absolute;
  top              : calc(50% - 0.6em);
  right            : 1em;
  transition       : $transition;
  transform-origin : center;
  color            : $red;
  font-size        : 1.2em;
  line-height      : 1.2em;

  &.plusOpen {
	transform : rotate(45deg);
  }
}

.moreInfo {
  max-height     : 0;
  overflow       : hidden;
  transition     : $transition;
  display        : block;
  padding-top    : 0 !important;
  padding-bottom : 0 !important;

  &.visible {
	max-height     : 200px;
	padding-top    : $tablePaddingY !important;
	padding-bottom : $tablePaddingY !important;
	display        : table-cell;
	transition     : $transition;
  }
}

.closedRow {
  max-height : 0 !important;
  font-size  : 0 !important;
  border     : none !important;
  transition : $transition;

  &.openRow {
	max-height : 200px !important;
	font-size  : 12px !important;
	transition : $transition;
  }
}

.min {
  width       : 1%;
  white-space : nowrap;
}

@media screen and (max-width : 561px) {

  .table {
	width           : 100%;
	border-collapse : collapse;
  }

  .th {
	background  : #333;
	color       : white;
	font-weight : bold;
  }
  .td, .th {
	padding    : 6px;
	border     : 1px solid #ccc;
	text-align : left;
  }

  .results, .thead, .tbody, .th, .td, .tr {
	display : block;
  }

  .thead .tr {
	position : absolute;
	top      : -9999px;
	left     : -9999px;
  }

  .bgLight {
	background-color : transparent;
	border           : none !important;
  }


  .td {
	border        : none;
	border-bottom : 1px solid #eee;
	position      : relative;
	width         : 100%;
	min-height    : 50px;
  }

  .tr {
	margin-top : 15px;

	:before {
	  position      : absolute;
	  left          : .7em;
	  padding-right : 10px;
	  white-space   : nowrap;
	}

	.comfort {
	  font-size : 16px !important;

	  &:before {
		content : "Comfort";
	  }
	}

	.premium {
	  font-size : 16px !important;

	  &:before {
		content : "Premium";
	  }
	}
  }

  .infoRow {
	border-bottom : none;
  }

  .priceTd {
	background-color : $red !important;
	border           : 1px solid $red !important;
	color            : white;
  }

  .priceTr {
	margin-top : 50px;
  }
}