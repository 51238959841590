@import "../../Theme/variables.scss";

.image {
  max-width : 60%;
  display   : block;
  margin    : 0 auto;
}

.wrapper {
  position        : relative;
  background      : $white;
  margin-bottom   : 1.5rem;
  border          : 1px solid $gray;
  text-align      : center;
  height          : calc(100% - 1.5rem);
  display         : flex;
  justify-content : space-between;
  flex-direction  : column;
}

.title {
  display : block;
  padding : 1em 1.5rem;
}

.toolbar {
  border-top      : 1px solid $gray;
  display         : flex;
  flex-direction  : row;
  justify-content : stretch;
  align-items     : center;

  & > * {
	flex       : 1;
	text-align : center;
  }

  & > button {
	color         : $red;
	background    : transparent !important;
	border-radius : 0;
	border        : none;
	position      : static !important;
	transition    : $transition;
	padding       : .25em;
	font-size     : 2em;

	&:disabled {
	  color : inherit;
	}

	&:disabled:hover {
	  background : transparent !important;
	}

	&:not(:disabled):hover {
	  color      : $white;
	  background : $red !important;
	}
  }
}
