@import "../../Theme/variables";

.tooltipContainer {
  position : relative;
  display  : inline-block;
}

.tooltipBox {
  position      : absolute;
  background    : transparentize($white, .1);
  color         : $red;
  padding       : 5px;
  border-radius : 5px;
  top           : calc(100% + 5px);
  display       : none;
  z-index       : 90001;
  min-width     : 250px;
  font-weight   : initial;
  font-size     : .8em;

  &.visible {
	display : block;
  }
}

.tooltipArrow {
  position     : absolute;
  top          : -10px;
  left         : 5px;
  border-width : 5px;
  border-style : solid;
  border-color : transparent transparent transparentize($white, .1) transparent;
  z-index      : 90001;
}

.info {
  width  : 15px;
  height : 15px;
}
