@import "../../Theme/variables.scss";

.main {
  background : $white;
  max-width  : 1110px;
}

a:hover, a:focus {
  color : #000;
}
