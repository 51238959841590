@import "./variables.scss";

.bigRedButton {
  text-transform : uppercase;
  font-weight    : 700;
  padding        : .7em 3.5em;
  background     : $red;
  transition     : $transition;
  color          : $white;
  border         : 2px solid $red !important;
  border-radius  : 0;

  &:hover, &:focus, &:active {
	transform  : scale(1.05);
	background : $red;
	color      : $white;
	border     : 2px solid $red !important;
  }

  @media (max-width : 575px) {
	width : 100%;
  }
}

.customFormControl {
  line-height   : 1.5 !important;
  color         : #495057 !important;
  padding       : 15px !important;
  border-radius : 0px !important;
  font-size     : 1em;

  &:focus {
	color            : #495057 !important;
	background-color : $white !important;
	border-color     : #495057 !important;
	outline          : 0 !important;
	box-shadow       : 0 0 0 0rem transparent !important;
  }

  &::placeholder {
	color : #495057;
  }
}

.restartBtn {
  color            : #111 !important;
  padding          : 10px 20px 10px 20px !important;
  border           : 2px solid #111 !important;
  background-color : transparent !important;
  transition       : $transition !important;
  border-radius    : 0 !important;

  &:hover {
	background-color : #111 !important;
	border           : 2px solid #111 !important;
	color            : $white !important;
	border-radius    : 0 !important;
  }

  @media (max-width : 575px) {
	width : 100%;
  }
}
