@import "../../Theme/variables.scss";

.append {
  font-size             : 14px;
  line-height           : 1.428571429;
  color                 : #444;
  vertical-align        : middle;
  background-color      : #FCFCFC;
  background-image      : none;
  border                : 1px solid #E6E6E6;
  -moz-transition       : all ease-in-out .15s;
  -webkit-transition    : all ease-in-out .15s;
  transition            : all ease-in-out .15s;
  -moz-box-shadow       : none;
  -webkit-box-shadow    : none;
  box-shadow            : none;
  -moz-border-radius    : 4px;
  -webkit-border-radius : 4px;
  border-radius         : 4px;
  border-left           : none;
}

.buttonLabel {
  background    : $white;
  font-size     : 1em;
  margin-right  : 1em;
  margin-bottom : 1em;
  padding       : .5em 1.25em;
  border-radius : 0;

  &:hover {
	background : $red;
	color      : $white;
	transition : $transition;
  }

  &:focus {
	box-shadow : none;
  }

  input[type="radio"]:checked + & {
	background : $red;
	color      : $white;
	transition : $transition;
  }
}

.submit {
  width          : 50%;
  text-transform : uppercase;
  font-weight    : 700;
  padding        : 1em;
  background     : $red;
  transition     : $transition;
  color          : $white;
  border         : 2px solid $red !important;
  border-radius  : 0;

  &:hover, &:focus, &:active {
	background : $white !important;
	color      : $red;
  }
}

.br0 {
  border-radius : 0px !important;
}

.borderGray {
  border           : 1px solid #e6e6e6;
  background-color : #fcfcfc;
  transition       : $transition;

  &:hover {
	border     : 1px solid $red;
	transition : $transition;
  }
}