@import "../../Theme/variables";

.item {
  background    : $white;
  color         : inherit;
  padding       : 10px;
  border-bottom : 1px solid #495057;

  &:hover {
	background : $red;
	color      : $white;
	cursor     : pointer;
  }
}

.highlighted {
  background : $red;
  color      : $white;
}
