@import "../../Theme/variables.scss";

.append {
  font-size             : 14px;
  line-height           : 1.428571429;
  color                 : #444;
  vertical-align        : middle;
  background-color      : #FCFCFC;
  background-image      : none;
  border                : 1px solid #E6E6E6;
  -moz-transition       : all ease-in-out .15s;
  -webkit-transition    : all ease-in-out .15s;
  transition            : all ease-in-out .15s;
  -moz-box-shadow       : none;
  -webkit-box-shadow    : none;
  box-shadow            : none;
  -moz-border-radius    : 4px;
  -webkit-border-radius : 4px;
  border-radius         : 4px;
  border-left           : none;
}

.buttonLabel {
  background   : $white;
  font-size    : 1em;
  margin-right : 1em;
  padding      : .5em 1.25em;

  &:hover {
	background : $red;
	color      : $white;
	transition : $transition;
  }

  input[type="radio"]:checked + & {
	background : $red;
	color      : $white;
	transition : $transition;
  }
}

.hovalUl {
  list-style   : none;
  padding-left : 20px;

  & > li {
	letter-spacing : 0.6px;
	padding-right  : 5px;
	list-style     : none;

	&::before {
	  content          : "";
	  display          : inline-block;
	  width            : 10px;
	  height           : 10px;
	  background-color : #E30613;
	  margin-left      : -20px;
	  margin-right     : 10px;
	}
  }
}

.submit {
  width          : 50%;
  text-transform : uppercase;
  font-weight    : 700;
  padding        : 1em;
  background     : $red;
  transition     : $transition;
  color          : $white;
  border         : 2px solid $red !important;
  border-radius  : 0;

  &:hover, &:focus, &:active {
	background : $white !important;
	color      : $red;
  }
}

.productImg {
  max-height : 250px;
}

.bg-result {
  background : #F8F9FA;
}

.parargraphSmall {
  font-size : 12px;
}

.textRed {
  color : $red !important;
}

.separator {
  border-color : $red;
  margin       : 5em 0;
  border-width : 4px;
}

.smallText {
  font-size : .85em;
}

.checkCircle {
  width : 15px;
}

.padMe {
  margin-top    : 10rem;
  margin-bottom : 14rem;
}
